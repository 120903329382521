import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import ForgotPasswordSuccess from 'src/sections/Auth/ForgotPassword/Success';
import ApiWrapper from 'src/components/ApiWrapper';

const ForgotPasswordSuccessPage: React.FC = () => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo title="Success" />
      <ForgotPasswordSuccess />
    </Layout>
  </ApiWrapper>
);

export default ForgotPasswordSuccessPage;

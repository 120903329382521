import React from 'react';
import Button from 'src/components/core/Button';
import Typography from 'src/components/core/Typography';
import { useLink, useQueryParam } from 'src/hooks';
import AuthBackground from '../Background';

function Success() {
  const { href: loginHref } = useLink('/login');
  const { href: forgotPasswordHref } = useLink('/forgot_password');
  const email = useQueryParam('email');

  return (
    <AuthBackground>
      <div className="py-8 px-8 sm:px-16 sm:py-14">
        <Typography
          color="blue"
          variant="h3"
        >
          Check your email
        </Typography>
        <Typography
          className="mt-2"
          variant="p3"
          color="textSecondary"
        >
          Please go to your
          {' '}
          <span className="font-bold text-teal">{email}</span>
          {' '}
          email and click the reset password link we&apos;ve sent to you.
        </Typography>
        <Typography
          className="mt-2"
          variant="p3"
          color="textSecondary"
        >
          If you don&#39;t receive this email within a few minutes,
          please check your spam folder. If you find your
          confirmation email there, select the message and
          click &quot;Not Spam&quot;. Then add Bizwise to your Contacts list.
          This will help future messages get through.
        </Typography>
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Button
            variant="filled"
            color="teal"
            href={loginHref}
            roundFull
            className="w-full"
          >
            Done
          </Button>
          <Button
            variant="filled"
            color="white"
            href={forgotPasswordHref}
            roundFull
            className="w-full"
          >
            Try Again
          </Button>
        </div>
      </div>
    </AuthBackground>
  );
}

export default Success;
